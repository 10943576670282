<template>
  <div id="tJoinView" class="display-flex flex-center">
    <div id="tJoinContainer" class="text-center">
      <h1 class="c-l">您收到了{{ info.companyName }}团队的邀请</h1>
      <p>Xcanvas -可视化工作平台，随时随地创造！</p>
      <p class="c-h mb-15">（同时支持安卓、iOS、平板等多平台）</p>
      <p class="mb-30">
        <b>{{ info.name }}</b
        ><span class="c-h">（{{ info.email }}）</span>邀请您加入
      </p>
      <div class="display-flex flex-center mb-30">
        <el-button class="btn-close" @click="joinTeamSubmit(0)">取消</el-button>
        <el-button type="warning" @click="joinTeamSubmit(1)">加入</el-button>
      </div>
      <p>您团队的Xpaper的域名为：</p>
      <p class="c-l mb-15"><b>xcanvas.com</b></p>
      <p>您登录的邮箱为：</p>
      <p class="c-l">
        <b>{{ xppUserInfo.email }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Tjoin",
  beforeRouteEnter(to, rfrom, next) {
    let xppUserInfo = JSON.parse(localStorage.getItem("XppUserInfo"));
    if (xppUserInfo.companyId) {
      // next("/");
      next();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(["xppUserInfo"])
  },
  data() {
    return {
      info: {
        companyId: "",
        headImg: "",
        companyName: "",
        name: "",
        time: 0,
        email: ""
      }
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      let { info } = await this.$axios.post(
        "/dis/team/getkey?key=" + this.$route.params.info
      );
      Object.assign(this.info, info);
    },
    async joinTeamSubmit(status) {
      await this.$axios.post("/dis/team/invitationOperation", {
        team_id: this.info.companyId,
        key: this.$route.params.info,
        status
      });
      if (status === 1) {
        this.xppUserInfo.companyId = this.info.companyId;
        this.$SaveXppUserInfo(this.xppUserInfo);
      }
      this.joinTeamClose();
    },
    joinTeamClose() {
      this.$router.push("/");
    },
    ...mapMutations(["$SaveXppUserInfo"])
  }
};
</script>

<style lang="scss" scoped>
#tJoinView {
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
}
#tJoinContainer {
  position: relative;
  flex: none;
  box-sizing: border-box;
  width: 600px;
  height: auto;
  min-height: 500px;
  padding: 25px 30px;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 65px;
  color: #373839;
  font-size: 17px;
  line-height: 1.8;
  font-weight: 500;
  &::before {
    content: "";
    position: absolute;
    top: -65px;
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background: url(/img/ic_colored.png) no-repeat center top/50px 50px;
  }
  .c-l {
    color: #31cd97;
  }
  .c-h {
    color: #e49a22;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  h1 {
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 15px;
  }
  .el-button {
    width: 165px;
    height: 62px;
    font-size: 18px;
    font-weight: 700;
  }
  .btn-close {
    border: 1px solid #e49a22;
    color: #e49a22;
    &:hover {
      background-color: #fff9ef;
    }
  }
}
</style>
